@font-face {
  font-family: 'Kaligiri';
  src: url('../fonts/kaligiri/regular.woff2');
}

@font-face {
  font-family: 'Nunki';
  src: url('../fonts/nunki/regular.woff2');
}

@font-face {
  font-family: 'Nunki Display';
  src: url('../fonts/nunki/black.woff2');
}

@font-face {
  font-family: 'M+';
  src: url('../fonts/mplus/regular.woff');
}
