@import 'typography.css';
$footer-color: white;

body {
  font-size: 62.5%;
  font-family: 'Nunki';
  margin: 0;
  width:100vw;
  background-color: #bae4f9;
  background-color: black;
}

div {
  box-sizing: border-box;
}

h1.display {
  font-family: 'Kaligiri';
  font-size: 80px;
  color: #f76a3f;

  @media screen and (max-height: 400px) {
    font-size: 40px;
  }
}

h2 {
  font-family: 'Nunki Display';
  font-size: 30px;

  @media screen and (max-height: 400px) {
    font-size: 50px;
  }
}

p {
  font-size: 30px;
  @media screen and (max-height: 400px) {
    font-size: 15px;
  }
}

.displaySpan {
  font-size: 50px;
  color: #ff6140;
  color: #f76a3f;

  @media screen and (max-width: 400px) {
    font-size: 25px;
  }
}

.container {
  padding: 90px;

  @media screen and (max-width: 768px) {
    padding: 60px;

    #mg {
      width: 100%;
      text-align: center;
    }
  }

  @media screen and (max-width: 400px) {
    padding: 30px;
  }

  code {
    margin: 10px;
    font-size: 20px;
    font-family: 'M+';
  }

}

.footer {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: rgb(70,70,70);
  display: flex;
  justify-content: center;
  font-family: 'M+';
  color: $footer-color;

  svg {
    fill: $footer-color;
  }
}

h2 {
  margin-bottom: 10px;
}

p {
  margin-top: 10px;
}
#about {
  width: 500px;
  @media screen and (max-width: 1024px) {
    width: 500px;
  }

  @media screen and (max-width: 600px) {
    width: 330px;
  }
}

.mainImage {
  position: relative;
}

.mainImage::after {
  content: "";

  //background-image: url('../assets/IMG_7799.jpg');
  background-image: url('../assets/MATT_G.jpg');

  background-size: cover;

  background-repeat: no-repeat;

  @media screen and (orientation: landscape) {
    background-size: calc(100vw + 100px) auto;
    background-size: cover;
    background-position: 0 0;
  }


  position: absolute;
  top: -300px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: -1;
}

input {
  width: 400px;
  border-radius: 4px;
  box-shadow: none;
  height: 60px;
  line-height: 30px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 20px;
  font-family: 'Nunki';
  border: solid 2px black;
  box-shadow: none;
  margin-bottom: 20px;
}

label {
  font-size: 20px;
}

a {
  color: inherit;
  text-decoration: none;
}
